import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Grid, Row, Col } from 'react-flexbox-grid'

import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Content, { HTMLContent } from '../components/Content'
import ResponsiveTitle from '../components/ResponsiveTitle'

const ImageWrapper = styled.div`
  max-width: 200px;
`

export const GivingBackPageTemplate = ({ title, content, contentComponent, image }) => {
  const PageContent = contentComponent || Content
  return (
    <Grid fluid>
      <Row>
        <ResponsiveTitle title={title} />
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <ImageWrapper>
            <PreviewCompatibleImage imageInfo={image} />
          </ImageWrapper>
        </Col>
        <Col xs={12} md={9}>
          <PageContent className="content" content={content} />
        </Col>
      </Row>
    </Grid>
  )
}

GivingBackPageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  image: PropTypes.object,
}

const GivingBackPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout>
      <GivingBackPageTemplate
        title={frontmatter.title}
        image={frontmatter.image}
        content={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

GivingBackPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default GivingBackPage

export const pageQuery = graphql`
  query GivingBackPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "giving-back-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
